.hero-section {
  width: 100vw;
  background: var(--hero-section-gradient);
}
.hero-section-wrapper {
  padding: 10px 20px;
  flex-direction: column;
  padding-top: 160px;
  width: 95%;
}

.hero-top-section,
.hero-bottom-section {
  width: 100%;
  flex-direction: column;
}
.hero-top-section img:nth-child(1) {
  margin: 20px 0px;
}
.hero-top-section img:nth-child(2) {
  margin: 0px 0px 20px 0px;
}
.hero-top-section h2 {
  margin: 0px 0px 20px 0px;
  font-weight: 330;
  color: var(--gray-color);
}
.hero-top-section button {
  width: 280px;
  height: 60px;
}
.hero-bottom-section {
  margin-top: 60px;
  margin-bottom: 100px;
}
.hero-bottom-section img {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .hero-top-section {
    width: 80%;
  }
}

@media screen and (min-width: 1200px) {
  .hero-section-wrapper {
    width: 90%;
    height: 100vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
  }
  .hero-top-section {
    width: 65%;
    margin-top: -160px;
  }
  .hero-top-section h2 {
    font-size: 34px;
  }
  .hero-bottom-section {
    width: 65%;
    margin-top: 0px;
  }
}
