.ourservices-section {
  width: 95%;
  margin-bottom: 100px;
}

.ourservices-section h1 {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
}

.ourservices-section-contents-item {
  box-shadow: var(--box-shadow);
  padding: 20px 20px;
  margin-top: 10px;
  border-radius: 10px;
}
.ourservices-section-contents-item img {
  max-width: 100px;
}
.ourservices-section-contents-item h1 {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}
.ourservices-section-contents-item p {
  font-size: 0.8rem;
  font-weight: 300;
  color: var(--dark-gray-color);
  margin-top: -45px;
}
.ourservices-section-contents-item-right {
  margin-top: -40px;
  margin-left: 40px;
}
@media screen and (min-width: 1200px) {
  .ourservices-section-contents {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .ourservices-section-contents-item {
    flex-direction: column;

    margin-bottom: 10px;
    width: 30%;
  }
  .ourservices-section-contents-item-right {
    margin-left: 0px;
  }
  .ourservices-section-contents-item img {
    max-width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
}
