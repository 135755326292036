.social-links-section {
  width: 100%;
}
.social-links-section h1 {
  color: var(--white-color);
  text-align: center;
  margin-bottom: 30px;
}
.social-links-section-wrapper {
  flex-wrap: wrap;
}
.social-links-section-content-item {
  width: 48%;
  margin-left: 5px;
  margin-bottom: 5px;
  padding: 20px 20px;
}
.social-links-section-content-item img {
  max-width: 40px;
  height: 40px;
}
.social-links-section-content-item h2 {
  font-size: 0.9rem;
  color: var(--white-color);
  font-weight: 400;
  margin-top: 20px;
}
.social-links-section-content-item p {
  font-size: 0.8rem;
  color: var(--gray-color);
  font-weight: 400;
}
.social-links-section-content-item:nth-child(1) {
  background-color: var(--fb-color);
}
.social-links-section-content-item:nth-child(2) {
  background: var(--insta-color);
}
.social-links-section-content-item:nth-child(3) {
  background-color: var(--youtube-color);
}
.social-links-section-content-item:nth-child(4) {
  background-color: var(--twitter-color);
}
@media screen and (min-width: 1472px) {
  .social-links-section {
    margin-top: -40px;
  }
}
