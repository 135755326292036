.featured-section-wrapper {
  width: 95%;
  padding: 60px 0px;
}
.featured-section-wrapper .featured-title {
  margin-bottom: 50px;
  max-width: 95%;
}
.featured-contents {
  margin-top: 25px;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.featured-contents-item {
  margin: 0px 0px 20px 10px;

  position: relative;
}

.featured-contents-item img {
  max-width: 80px;
  height: 60px;
}
.featured-contents-item h2 {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: 700;
}
.featured-contents-item p {
  margin-bottom: 40px;
  font-size: 0.9rem;
  color: var(--dark-gray-color);
  font-weight: 300;
}
@media screen and (min-width: 1000px) {
  .featured-section-wrapper {
    width: 85%;
  }
  .featured-contents {
    width: 85%;
  }
  .featured-contents-item img {
    max-width: 120px;
    height: 120px;
  }
  .featured-contents-item h2 {
    font-size: 1.6rem;
  }
  .featured-contents-item p {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1400px) {
  .featured-contents {
    width: 75%;
  }
}
