.why-choose-us-section {
  width: 100%;
  background-color: rgb(236, 236, 236);
  padding: 20px 10px;
}
.why-choose-us-section-wrapper {
  margin-bottom: 50px;
  width: 95%;
}
.why-choose-us-section-wrapper .why-choose-us-section-title {
  margin-bottom: 20px;
  margin-top: 50px;
  max-width: 95%;
  font-size: 28px;
  letter-spacing: -0.1rem;
  color: var(--dark-gray-color);
}
.brain-storm {
  max-width: 130px;
  position: absolute;
  right: 40px;
  margin-top: -180px;
}
.why-choose-us-section-contents {
  padding: 10px 5px;
  flex-wrap: wrap;
}
.why-choose-us-section-contents-item {
  padding: 10px 5px;
  justify-content: space-between;
  width: 45%;
  margin-left: 10px;
}
.why-choose-us-section-contents-item img {
  max-width: 30px;
  position: absolute;
  box-shadow: var(--box-shadow);
  padding: 6px;
  border-radius: 50%;
}
.why-choose-us-section-contents-item h3 {
  font-size: 14px;
  font-weight: 400;
  color: var(--dark-gray-color);
  margin-left: 40px;
}

@media screen and (min-width: 1200px) {
  .why-choose-us-section-wrapper {
    width: 85%;
  }
  /* .why-choose-us-section-contents {
    width: 85%;
  } */
  .why-choose-us-section-contents-item img {
    max-width: 40px;
    padding: 8px;
  }
  .why-choose-us-section-contents-item h3 {
    font-size: 1.2rem;
    margin-left: 70px;
  }
  .brain-storm {
    max-width: 300px;
    position: absolute;
    right: 100px;
    margin-top: -180px;
  }
}
@media screen and (min-width: 1400px) {
  .featured-contents {
    width: 75%;
  }
}
