.about-section-wrapper {
  margin-bottom: 50px;
  width: 95%;
}
.about-section-wrapper .about-title {
  margin-bottom: 50px;
  max-width: 95%;
}
.about-section-wrapper p {
  margin-bottom: 50px;
  font-size: 14px;
  color: var(--dark-gray-color);
  font-weight: 300;
}
.about-section-contents {
  flex-direction: column;
  width: 95%;
}
.about-section-contents img {
  width: 80%;
}
@media screen and (min-width: 1000px) {
  .about-section-wrapper {
    width: 85%;
  }
  .about-section-contents {
    flex-direction: row;
    width: 95%;
    margin-top: 50px;
  }
  .about-section-contents img {
    width: 40%;
    margin-left: 10%;
  }
  .about-section-wrapper p {
    font-size: 18px;
  }
}
