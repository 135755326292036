@import url('https://use.typekit.net/dmj2iis.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
*,
#root,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --primary-font: 'brandon-grotesque', sans-serif;
  --secondary-font: 'Open Sans', sans-serif;
  --primary-color: #f25151;
  --black-color: #000000;
  --gray-color: #d1d1d1;
  --dark-gray-color: #828282;
  --white-color: #ffff;
  --fb-color: #465993;
  --insta-color: linear-gradient(
    73.43deg,
    #f04c5c 9.25%,
    #b63a9d 48.24%,
    #7447c4 96.28%
  );
  --youtube-color: #b20000;
  --twitter-color: #40bbf4;
  --hero-section-gradient: linear-gradient(180deg, #37bf7c 0%, #2c9a64 93.52%);
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  --mobile-screen: 375px;
  --ipad-screen: 768px;
  --laptop-screen: 1000px;
  --pc-screen: 1200px;
  --large-pc-screen: 1400px;
  --paragraph-mobile: 12px;
  --paragraph-pc: 16px;
  --title-mobile: 14px;
  --title-mobile: 18px;
  --box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}
.margin-center {
  margin-left: 50%;
  transform: translateX(-50%);
}
.flex {
  display: flex;
  align-items: center;
  justify-content: left;
}
.margin-auto {
  margin: 0 auto;
}
.primary-font {
  font-family: var(--primary-font);
  text-transform: uppercase;
  letter-spacing: -0.09rem;
}
.secondary-font {
  font-family: var(--secondary-font);
}
.btn {
  height: 50px;
  border: 0;
  border-radius: 50px;
  font-size: 16px;
  letter-spacing: -0.03rem;
  background-color: var(--primary-color);
  color: var(--white-color);
  width: 100%;
  cursor: pointer;
  opacity: 0.9;
  font-weight: 300;
}
.btn:hover,
.btn:active,
.btn:focus {
  opacity: 1;
  transform: scale(0.99);
  outline: none;
}
.wrapper {
  width: 95%;
}
.stroke-outlined {
  color: #000;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0.5px;
  letter-spacing: 0.03rem;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: var(--white-color);
  background-blend-mode: darken;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 20px;
}

@media screen and (min-width: 1000px) {
  .btn {
    font-size: 18px;
    letter-spacing: -0.003rem;
  }
  .wrapper {
    width: 85%;
  }
}
