.Navbar,
.Navbar-transparent {
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.Navbar {
  background-color: var(--primary-color);
}
.menu-item a {
  text-decoration: none;
}
.menu-item h1 {
  color: var(--white-color);
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.03rem;
  padding: 10px 20px;
  /* transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
}

.menu-item h1:hover + .underline {
  height: 4px;
  background: var(--insta-color);
  animation: hover-animation 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@keyframes hover-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.Logo,
.Logo {
  max-width: 160px;
  cursor: pointer;
  height: 50px;
}
.navbar-menu-items {
  width: 70%;
  justify-content: space-evenly;
}
.Burger,
.burger-active {
  width: 25px;
  height: 20px;
  margin-left: 4vw;
  cursor: pointer;
  position: absolute;
}
.burgerLine {
  width: 100%;
  height: 2px;
  background-color: var(--white-color);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.burgerLine:nth-child(2) {
  margin-top: 5px;
}
.burgerLine {
  margin-top: 5px;
}
.burger-active .burgerLine:nth-child(2) {
  display: none;
}
.burger-active .burgerLine:nth-child(1) {
  transform: rotate(45deg);
}
.burger-active .burgerLine:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -2px;
}
@media screen and (max-width: 800px) {
  .Logo,
  .Logo {
    position: absolute;
    max-width: 120px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: -20px;
  }
  .Navbar,
  .Navbar-transparent {
    height: 70px;
  }
  .navbar-menu-items-mobile-hide {
    transform: translateX(-100vw);
    transition: 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    position: fixed;
    top: 70px;
  }
  .navbar-menu-items-mobile {
    transform: translateX(0);
    transition: 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 70px;
    background-color: var(--primary-color);
    height: 100vh;
    width: 60vw;
    left: 0;
    bottom: 0;
  }
  .navbar-wrapper {
    margin-top: 50px;
    margin-left: -50px;
  }
  .navbar-menu-items-mobile .menu-item {
    margin-top: 20px;
  }
}
