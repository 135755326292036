.meet-team-section-wrapper {
  margin-bottom: 150px;

  width: 95%;
}
.meet-team-wrapper .meet-team-title {
  margin-bottom: 50px;
  max-width: 95%;
}
.meet-team-contents {
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
}
.meet-team-contents-item {
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  margin-left: 20px;
}
.meet-team-contents-item h1 {
  margin-top: 50px;
  font-size: 16px;
  color: var(--dark-color);
  font-weight: 600;
}
.meet-team-contents-item h4 {
  font-size: 14px;
  color: var(--dark-gray-color);
  font-weight: 300;
}

.meet-team-contents img {
  max-width: 100px;
  height: 100px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.meet-team-contents-item-left {
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -45px;
}
@media screen and (min-width: 1000px) {
  .meet-team-wrapper {
    width: 85%;
  }
  .meet-team-contents {
    flex-direction: row;
    width: 95%;
    margin-top: 50px;
  }
}
