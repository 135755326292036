.contact-info-section {
  width: 100%;
  margin-bottom: 50px;
}
.contact-info-wrapper {
  width: 95%;
  margin-top: 40px;
}
.contact-info-section h1 {
  color: var(--white-color);
  width: 100%;
  text-align: center;
}
.contact-info-item {
  width: 100%;
  margin-top: 20px;
}
.contact-info-item img {
  max-width: 40px;
  position: absolute;
}
.contact-info-item-right {
  padding-left: 60px;
}
.contact-info-item-right h2 {
  color: var(--white-color);
  font-weight: 400;
  font-size: 1rem;
}
.contact-info-item-right p {
  color: var(--dark-gray-color);
  font-size: 0.9rem;
}
@media screen and (min-width: 1000px) {
  .contact-info-section {
    margin-top: -80px;
  }
}
