.footer {
  background-color: var(--black-color);
  padding: 100px 0px 50px 0px;
}
.footer-content-wrapper {
  flex-direction: column;
  width: 95%;
}
.footer-right-section {
  flex-direction: column;
  margin-top: 40px;
}
@media screen and (min-width: 1000px) {
  .footer-content-wrapper {
    flex-direction: row;
  }
  .footer-right-section {
    flex-direction: row;
    align-items: center;
    width: 110%;
    margin-top: 0px;
  }
}
