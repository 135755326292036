.copyright-section {
  margin-top: 50px;
}
.copyright-section h3 {
  color: var(--dark-gray-color);
  font-size: 1rem;
  text-align: center;
}
.copyright-section span {
  margin-left: 2px;
}
