.contact-form {
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.form-header {
  color: var(--white-color);
  text-align: center;
  margin-bottom: 30px;
}
.form-subheader {
  color: var(--dark-gray-color);
  text-align: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.form-control {
  width: 100%;
}
.form-control input {
  border-radius: 30px;
  border: 0.5px solid var(--dark-gray-color);
  height: 50px;
  width: 100%;
  margin-top: 15px;
  padding-left: 30px;
  font-size: 0.9rem;
  background-color: transparent;
}
.form-control label {
  position: absolute;
  margin-top: 28px;
  left: 7vw;
  font-size: 0.9rem;
  color: var(--dark-gray-color);
  text-align: center;
}
.contact-form button {
  margin-top: 15px;
}
.contact-form input:focus,
.contact-form input:active,
.contact-form input:valid {
  outline: none;
  color: var(--white-color);
  border-color: var(--white-color);
}

.name-input:focus ~ .name-label,
.name-input:active ~ .name-label,
.name-input:valid ~ .name-label,
.email-input:focus ~ .email-label,
.email-input:active ~ .email-label,
.email-input:valid ~ .email-label,
.message-input:focus ~ .message-label,
.message-input:active ~ .message-label,
.message-input:valid ~ .message-label {
  transform: translateY(-25px);
  color: var(--dark-gray-color);
  /* left: calc(7vw - 30px); */
  background-color: var(--black-color);
  padding: 0px 10px;
}
@media screen and (min-width: 768px) {
  .form-control label {
    left: calc(6vw + 20px);
  }
}
@media screen and (min-width: 850px) {
  .form-control label {
    left: calc(7vw + 60px);
  }
}
@media screen and (min-width: 950px) {
  .form-control label {
    left: calc(7vw + 80px);
  }
}
@media screen and (min-width: 1000px) {
  .form-control label {
    left: 60px;
  }
  .contact-form-section {
    margin-right: 50px;
    margin-top: 10px;
  }
  .contact-info-footer {
    margin-top: -200px;
  }
}
@media screen and (min-width: 1500px) {
  .form-control label {
    left: 70px;
  }
}
